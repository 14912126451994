/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '@components/Layout'
import { Box } from '@components/Grid'
import { SEO } from '@components/SEO'
import { H1 } from '@components/Heading'
import { Text } from '@components/Text'

const PrivacyPage = ({ data: { content }, location }) => (
  <Layout>
    <SEO
      metadata={{
        title: content.seo.seoTitle,
        description: content.seo.seoDescription,
        pathname: location.pathname,
      }}
    />
    <Box p={[5, 6, 7]} maxWidth="900px" mx="auto">
      <H1 mb={7}>{content.heading}</H1>
      <Text content={content._rawText} />
    </Box>
  </Layout>
)

export const query = graphql`
  query PrivacyPage {
    content: sanityPrivacyPageSingleton {
      seo {
        seoDescription
        seoTitle
      }
      heading
      _rawText
    }
  }
`

export default PrivacyPage
